<template>
  <div class="fixed bottom-0 w-full">
    <div class="w-full h-[84px] bg-gradient-to-t from-[#222222] to-black rounded-tl-[31px] rounded-tr-[31px] border-l border-r border-t border-white border-opacity-40">
      <div class="flex justify-around items-center text-secondary-gray text-base font-medium mt-3">
        <router-link to="/stats" class="flex flex-col gap-1 items-center justify-center">
          <img :src="getIconSrc('/stats', 'social')" alt="friends" class="h-8">
          <h4 class="font-semibold text-white text-base text-opacity-80">Friends</h4>
        </router-link>
        <router-link to="/" class="flex flex-col gap-1 items-center justify-center w-11 h-11">
          <img :src="getIconSrc('/', 'mine')" alt="home" class="h-9">
          <h4 class="font-semibold text-white text-base text-opacity-80">Mine</h4>
        </router-link>
        <router-link to="/upgrades" class="flex flex-col gap-1 items-center justify-center">
          <img :src="getIconSrc('/upgrades', 'upgrades')" alt="upgrade" class="h-8">
          <h4 class="font-semibold text-white text-base text-opacity-80">Upgrades</h4>
        </router-link>
        <router-link to="/missions" class="flex flex-col items-center justify-center">
          <img :src="getIconSrc('/missions', 'missions')" alt="missions" class="h-8">
          <h4 class="font-semibold text-white text-base text-opacity-80">Missions</h4>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomBar',

  mounted() {
    this.listenBottomBar();
  },

  computed: {
    user() {
      return this.$store.state.user;
    },

    activeMissions() {
      return this.$store.state.missions.filter((mission) => !this.user.missionsDone.includes(mission.id)).length;
    },
  },

  methods: {
    listenBottomBar() {
      this.$router.afterEach(() => {
        this.$forceUpdate();
      });
    },
    isActive(path) {
      return this.$route.path === path;
    },
    getIconSrc(path, iconName) {
      return require(`../assets/bottom/${iconName}.svg`);
    }
  }
}
</script>
