<template>
    <div class="w-full h-screen">
        <h1 class="font-bold text-white text-3xl mt-5">{{ user.friends.length }} Friends</h1>
        <h3 class="font-semibold text-white text-sm">Invite a friend and earn bonus!</h3>
        <div class="w-full flex justify-center">
            <div class="w-full mt-5 bg-[#131313] rounded-[10px] p-5 m-5">
                <div class="flex-col justify-start items-start gap-[13px] inline-flex">
                    <div class="justify-start items-center gap-3 inline-flex">
                    <img class="w-[60px] h-[54px]" src="../assets/coin.svg" />
                    <div class="flex-col justify-start items-start gap-1 inline-flex">
                        <div class="text-[#fca137] text-base font-semibold font-['Poppins']">Arkadaşlarını davet et</div>
                            <div class="justify-start items-center gap-1 inline-flex">
                                <div class="text-white text-[10px] font-light font-['Poppins'] text-start">Senin ve arkadaşların için en fazla {{ store.state.appRewards.refReward }}</div>
                                <img class="w-[17px] h-4" src="../assets/coin.svg" />
                            </div>
                        </div>
                    </div>
                    <div class="h-[15px]">
                    <div class="w-[310px] h-[0px] left-0 top-[7px] border border-[#6b6b6b]/40"></div>
                    </div>
                    <div class="justify-start items-center gap-3 inline-flex">
                        <img class="w-[60px] h-[54px]" src="../assets/coin.svg" />
                        <div class="flex-col justify-start items-start gap-1 inline-flex">
                            <div class="text-[#fca137] text-base font-semibold font-['Poppins']">Arkadaşlarını davet et</div>
                            <div class="justify-start items-center gap-1 inline-flex">
                                <div class="text-white text-[10px] font-light font-['Poppins'] text-start">Senin ve arkadaşların için en fazla {{ store.state.appRewards.refClaimReward }}</div>
                                <img class="w-[17px] h-4" src="../assets/coin.svg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       <div class="w-screen flex mt-5 justify-center px-4 gap-3">
            <div class="rounded-full bg-white p-3 items-center flex w-full gap-4 mx-2 justify-center" v-on:click="copyRef">
                <h4 class="font-extrabold text-2xl text-black">Invite your friends</h4>
            </div>
        </div>
        <div class="flex flex-col justify-start mt-5 items-start px-5 w-full">
            <h1 class="text-white font-bold text-lg">{{ user.friends?.length ?? 0 }} Friends</h1>
            <div class="flex flex-col w-full max-h-96 overflow-scroll" v-if="user.friends?.length ?? 0 > 0">
                <div class="mt-3 h-20 bg-[#131313] border border-opacity-20 rounded-2xl p-3 flex items-center justify-between overflow-hidden" v-for="user in user.friends" :key="user">
                  <div class="flex items-center">
                      <div class="relative w-12 h-12 flex justify-center items-center flex-col">
                          <div class="absolute w-12 h-12 rounded-full border-secondary-yellow border-4"></div>
                          <h4 class="text-secondary-yellow text-xl font-bold">{{ user.name.charAt(0).toUpperCase() }}</h4>
                      </div>
                      <h4 class="text-white font-bold text-lg ms-3">{{ user.name }}</h4>
                  </div>
                  <div class="flex items-center gap-2">
                      <h4 class="text-white font-semibold text-xl">{{ user.totalEarned.toFixed(0) }}</h4>
                      <img :src="store.state.appIcon" alt="appicon" class="h-4 w-4 rounded-full"/>
                  </div>
              </div> 
            </div>

            <div v-else class="w-full justify-center">
                <h4 class="text-gray-500">No Friends Yet!</h4>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed } from 'vue';
import store from '@/store';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

const $toast = useToast();

const user = computed(() => {
  return store.state.user;
});

const copyRef = () => {
    const el = document.createElement('textarea');
    el.value = `t.me/Patheum_bot/app?startapp=${user.value.telegramId}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    $toast.success("Ref link has been copied to clipboard!", { position: 'top', duration: 1500 });
}
</script>

<style>
.card {
  background: rgb(62,54,72);
  background: linear-gradient(90deg, rgba(62,54,72,0.5) 0%, rgba(60,62,77,0) 100%);
}
</style>