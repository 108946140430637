<template>
    <div class="h-screen bg-[#030303] flex flex-col justify-start items-center p-4">
        <div class="flex gap-2 justify-center items-center mt-5">
            <img src="../assets/coin.svg" alt="coin">
            <h4 class="text-white font-bold text-4xl">{{ store.state.user.balance.toFixed(0) }}</h4>
        </div>
        <h1 class="text-white font-bold text-base mt-5">Boosts</h1>
        <!-- Tap Upgrade -->
        <div class="mt-3 w-full bg-[#131313] rounded-2xl p-4 flex items-center justify-between relative overflow-hidden">
            <div class="flex items-center">
                <img src="../assets/coin.svg" alt="box" style="height: 60px; width: 60px;" class="rounded-full">
                <div class="flex flex-col items-start ms-3 gap-2">
                    <h3 class="text-lg text-[#FCA137]">{{ nextTapUpgrade?.name ?? currentTapUpgrade.name }}</h3>
                    <div class="flex gap-2 items-center justify-center">
                        <img src="../assets/coin.svg" alt="coin" style="height: 15px; width: 15px;" class="rounded-full">
                        <h4 class="text-white text-start text-base">{{ nextTapUpgrade?.price ?? currentTapUpgrade.price }}</h4>
                        <div class="w-1 h-1 bg-white rounded-full"></div>
                        <h4 class="text-white text-start text-base">Level {{ nextTapUpgrade?.level ?? currentTapUpgrade.level }}</h4>
                    </div>
                </div>
            </div>
            <div v-if="nextTapUpgrade" class="p-3 text-white rounded-xl cursor-pointer" v-on:click="showModal(currentTapUpgrade, 'tap')">
                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99705 9L0 1.00164L1.00147 0L10 9L1.00147 18L0 16.9984L7.99705 9Z" fill="white"/>
                </svg>
            </div>
            <div v-else class="p-3 text-white rounded-xl">
                Last Level
            </div>
        </div>

        <!-- Energy Upgrade -->
        <div class="mt-3 w-full bg-[#131313] rounded-2xl p-4 flex items-center justify-between relative overflow-hidden">
            <div class="flex items-center">
                <img src="../assets/energy.svg" alt="box" style="height: 60px; width: 60px;" class="rounded-full">
                <div class="flex flex-col items-start ms-3 gap-2">
                    <h3 class="text-lg text-[#FCA137]">{{ nextEnergyUpgrade?.name ?? currentEnergyUpgrade.name }}</h3>
                    <div class="flex gap-2 items-center justify-center">
                        <img src="../assets/coin.svg" alt="coin" style="height: 15px; width: 15px;" class="rounded-full">
                        <h4 class="text-white text-start text-base">{{ nextEnergyUpgrade?.price ?? currentEnergyUpgrade.price }}</h4>
                        <div class="w-1 h-1 bg-white rounded-full"></div>
                        <h4 class="text-white text-start text-base">Level {{ nextEnergyUpgrade?.level ?? currentEnergyUpgrade.level }}</h4>
                    </div>
                </div>
            </div>
            <div v-if="nextEnergyUpgrade" class="p-3 text-white rounded-xl cursor-pointer" v-on:click="showModal(currentEnergyUpgrade, 'energy')">
                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99705 9L0 1.00164L1.00147 0L10 9L1.00147 18L0 16.9984L7.99705 9Z" fill="white"/>
                </svg>
            </div>
            <div v-else class="p-3 text-white rounded-xl">
                Last Level
            </div>
        </div>
        <UpgradeModal :nextUpgrade="nextUpgrade" @upgradeTap="upgrade(selectedUpgradeType)" @closeModal="closeModal" :type="selectedUpgradeType" />
    </div>
</template>

<script setup>
import store from '@/store';
import { computed, ref } from 'vue';
import UserService from '@/service/userService';
import { useToast } from 'vue-toast-notification';
import UpgradeModal from './UpgradeModal.vue';

const selectedUpgradeType = ref('');
const toast = useToast();

const currentTapUpgrade = computed(() => {
    return store.state.tapUpgrades.find(box => box.level == store.state.user.tapClaimLevel);
});

const currentEnergyUpgrade = computed(() => {
    console.log('Energy', store.state.energyUpgrades);
    return store.state.energyUpgrades.find(box => box.level == store.state.user.energyLevel);
});

const showModal = (upgrade, upgradeType) => {
    selectedUpgradeType.value = upgradeType;
    nextUpgrade.value = findNextUpgrade(upgrade, upgradeType);
    document.getElementById('upgrade_modal').showModal();
}

const findNextUpgrade = (upgrade, upgradeType) => {
    const upgrades = upgradeType === 'tap' ? store.state.tapUpgrades : store.state.energyUpgrades;
    const currentIndex = upgrades.findIndex(u => u._id === upgrade._id);
    return upgrades[currentIndex + 1] || null;
}

const nextTapUpgrade = computed(() => {
    return findNextUpgrade(currentTapUpgrade.value, 'tap');
});

const nextEnergyUpgrade = computed(() => {
    return findNextUpgrade(currentEnergyUpgrade.value, 'energy');
});

const nextUpgrade = computed(() => {
    return selectedUpgradeType.value === 'tap' ? nextTapUpgrade.value : nextEnergyUpgrade.value;
});

const upgrade = async (upgradeType) => {
    try {
        let res;
        if (upgradeType === 'tap') {
            res = await UserService.upgradeTap(store.state.user.telegramId);
        } else if (upgradeType === 'energy') {
            res = await UserService.upgradeEnergy(store.state.user.telegramId);
        }
        
        if (res?.user) {
            store.commit('setUser', res.user);
            
            if (upgradeType === 'tap') {
                store.commit('setTapClaimReward', res.tapClaimReward);
            } else if (upgradeType === 'energy') {
                store.commit('setEnergyClaimReward', res.energyClaimReward);
            }

            closeModal();
            
            toast.success('Successfully upgraded!', { position: 'top', duration: 1500 });
        } 
    } catch(e) {
        toast.error(e.response.data.error, { position: 'top', duration: 1500 });
        closeModal();
    }
}

const closeModal = () => {
    document.getElementById('upgrade_modal').close();
}

</script>