<template>
    <dialog id="upgrade_modal" class="modal modal-bottom sm:modal-middle rounded-t-2xl p-0">
            <div class="modal-box h-[550px] bg-gradient-to-t from-[#222222] to-black rounded-tl-[31px] rounded-tr-[31px] border-l border-r border-t border-white border-opacity-50 flex flex-col justify-start items-center">
                <button class="icon-button absolute top-4 right-4" aria-label="Close" @click="closeModal">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.0693 0.000495159C28.2555 0.08988 36.232 8.20359 35.995 18.4788C35.7676 28.3179 27.783 36.0673 17.8513 35.9996C7.69758 35.9318 -0.412937 27.4498 0.0162846 17.2138C0.415718 7.66998 8.3448 -0.0712836 18.0693 0.000495159ZM18.1045 21.8713C18.2507 22.0975 18.3509 22.3074 18.4999 22.4699C19.6115 23.6821 20.6988 24.9172 21.8605 26.0792C22.8517 27.0692 24.3181 27.0773 25.3552 26.2052C26.3586 25.3601 26.5969 23.9164 25.8576 22.7828C25.5529 22.3156 25.1305 21.9242 24.75 21.5097C23.7033 20.3721 22.6513 19.2413 21.5911 18.0955C22.9464 16.6342 24.2558 15.2365 25.5475 13.8226C26.4642 12.8204 26.5319 11.481 25.7533 10.4165C25.0465 9.45226 23.7128 9.07035 22.6215 9.61749C22.134 9.86262 21.7021 10.2621 21.3176 10.6617C20.1992 11.8291 19.1187 13.0317 18.0111 14.2316C16.7722 12.8976 15.5969 11.6192 14.4081 10.3542C13.6864 9.58634 12.8388 9.14619 11.7501 9.46039C9.70964 10.0482 9.06513 12.2543 10.4923 13.8348C11.7745 15.2569 13.0893 16.6491 14.4135 18.0793C14.2632 18.254 14.1386 18.4097 14.0032 18.556C12.8415 19.8155 11.6784 21.0723 10.5166 22.3318C9.44155 23.4992 9.4754 25.0892 10.5938 26.1293C11.6892 27.1478 13.2301 27.0651 14.3268 25.9072C15.5481 24.6179 16.7546 23.3137 18.1045 21.8686V21.8713Z" fill="#4C4E52"/>
                    </svg>
                </button>
                <div class="flex w-full justify-center items-center">
                    <img src="../assets/coin.svg" alt="coin" style="height: 250px; width: 250px;" v-if="props.type === 'tap'" >
                    <img src="../assets/energy.svg" alt="icon" style="height: 250px; width: 250px;" v-if="props.type === 'energy'" >
                </div>
                <h3 class="text-3xl font-extrabold text-white">{{ props.nextUpgrade?.name ?? '' }}</h3>
                <div class="flex gap-2 mt-10 items-center justify-center">
                    <img src="../assets/coin.svg" alt="coin" style="height: 35px; width: 35px;">
                    <h3 class="text-2xl font-bold text-white">{{ props.nextUpgrade?.price ?? '' }}</h3>
                </div>
                <button class="bg-white rounded-full p-3 text-black text-xl font-bold w-full mt-5" @click="upgradeTap">Buy</button>
            </div>
        </dialog>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
    type: String,
    nextUpgrade: Object,
});

const emit = defineEmits(['upgradeTap', 'closeModal']);

const upgradeTap = () => {
  emit('upgradeTap');
};

const closeModal = () => {
  emit('closeModal');
};
</script>