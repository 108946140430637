<template>
  <div class="home h-screen bg-[#030303]">
    <div class="w-full flex flex-col justify-start items-center mt-3 px-3 relative">
      <div class="flex gap-2 w-full justify-center" v-on:click="shareRef">
        <div class="rounded-full border border-white w-3/4 bg-opacity-5 flex items-center justify-center">
          <h3 class="text-2xl font-bold text-white">Join Team!</h3>
        </div>
        <div class="rounded-full p-2 border border-white">
          <img src="../assets/reward.png" alt="reward" style="height: 30px; width: 30px;">
        </div>
      </div>
      <div class="flex items-center justify-start mt-4 gap-5">
        <div class="bg-gradient-to-b from-[#222222] to-black rounded-[10px] items-center flex flex-col border-l border-r border-t border-white px-3 py-1 border-opacity-65" v-on:click="showModal">
          <img src="../assets/reward.svg" alt="asd" style="height: 40px; width: 40px;">
          <h4 class="text-xs font-semibold text-white mt-1">Daily Rewards</h4>
          <h4 class="font-bold text-white text-opacity-70" style="font-size: 13px;">{{ store.state.dailyClaimCountdown }}</h4>
        </div> 
        <div class="bg-gradient-to-b from-[#222222] to-black rounded-[10px] items-center flex flex-col border-l border-r border-t border-white px-3 py-1 border-opacity-65" v-on:click="showComboModal">
          <img src="../assets/combo.svg" alt="" style="height: 40px; width: 40px;">
          <h4 class="text-xs font-semibold text-white mt-1">Daily Combo</h4>
          <h4 class="font-bold text-white text-opacity-70" style="font-size: 13px;">{{ store.state.comboCountdown }}</h4>
        </div>  
        <div class="bg-gradient-to-b from-[#222222] to-black rounded-[10px] items-center flex flex-col border-l border-r border-t border-white px-3 py-1 border-opacity-65" v-on:click="openWheelModal">
          <img src="../assets/fortune-wheel.png" alt="" style="height: 40px; width: 40px;">
          <h4 class="text-xs font-semibold text-white mt-1">Daily Wheel</h4>
          <h4 class="font-bold text-white text-opacity-70" style="font-size: 13px;">{{ store.state.wheelCountdown }}</h4>
        </div>
      </div>

      <div class="gap-1 rounded-2xl flex items-center justify-center mx-5 overflow-hidden relative text-white opacity-100 mt-5">
         <img src="../assets/coin.svg" alt="appicon" class="relative" style="height: 55px; width: 55px; border-radius: 100%">
          <h4 class="text-white font-bold text-4xl relative">
            {{ user.balance.toLocaleString('tr-TR', { maximumFractionDigits: 0 }) }}
          </h4>
      </div>
      <div class="flex justify-center items-center content-center" v-on:click="showLeaderboard">
          <img :src="userRank?.icon" alt="rank" class="h-4 w-4 rounded-full">
          <h1 class="text-white font-normal text-base ms-2">{{ userRank?.name }}</h1>
      </div>
      <div class="flex w-full h-[250px] justify-center items-center mt-4 transition-transform duration-150" v-on:click="tapClaim" ref="card">
        <div class="justify-center w-full items-center absolute">
          <div class="w-[307px] h-[307px] absolute top-[20px] bg-[#7f00ff] rounded-full blur-[145px] pointer-events-none md:backdrop-filter md:backdrod-fluter-[145px]"></div>
          <div class="absolute w-full flex justify-center">
            <!-- <div class="border-[8px] rounded-full h-64 w-64 absolute from-[#5F5F5F] to-[#9D9D9D]"></div> -->
             <img src="../assets/ellipse.svg" alt="circle" class="h-72 w-72 rounded-full">
          </div>
          <div class="w-full flex justify-center mt-10 relative">
            <img src="../assets/hero.svg" alt="coin" class="h-52 w-52">
          </div>
        </div>
      </div>
      <div
        v-for="click in clicks"
        :key="click.id"
        class="absolute text-5xl font-bold opacity-0 text-white pointer-events-none"
        :style="{
          top: `${click.y - 42}px`,
          left: `${click.x - 28}px`,
          animation: 'float 1s ease-out'
        }"
        @animationend="handleAnimationEnd(click.id)"
      >
        {{ store.state.tapClaimReward }}
      </div>
    </div>
    <div class="w-full flex px-3 justify-between items-center mt-12">
      <div class="flex flex-col items-start gap-1">
        <div class="flex items-center gap-1 ms-2">
          <div style="height: 20px; width: 20px;">
            <svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 13.5H1L4.5 1.5H11L8 9.5H13L5 21.5L6 13.5Z" stroke="white" stroke-width="2" stroke-linejoin="round"/></svg>
          </div>
          <h3 class="font-bold text-white text-base">{{ user.energy.toFixed(0) }} / <span class="text-[#5b5b5b] text-base font-normal">{{ store.state.maxEnergy ?? 0 }}</span></h3>
        </div>
        <div class="flex items-center justify-center content-center mt-1">
          <div class="rounded-xl bg-slate-500 h-3 w-44 relative overflow-hidden">
            <div class="rounded-2xl bg-white absolute h-3" :style="{ width: (user.energy / (store.state.maxEnergy ?? 1)) * 100 + '%' }"></div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="w-[165px] h-[42px] px-[59px] py-[9px] bg-[#272727] rounded-[49px] border-l border-r border-t border-b border-white justify-center items-center gap-2.5 inline-flex" v-on:click="routeBoosts">
        <img src="../assets/rocket.png" alt="rocket" class="h-6 w-6">
        <div class="text-white text-xl font-bold">Boosts</div>
      </div>
    </div>
    
      <dialog id="combo_modal" class="modal modal-bottom sm:modal-middle rounded-t-2xl p-0 text-white">
        <div class="modal-box h-4/5 rounded-t-2xl p-0 border-w bg-gradient-to-t from-[#222222] to-black rounded-tl-[31px] rounded-tr-[31px] border-l border-r border-t border-white border-opacity-50">
          <button class="icon-button absolute top-4 right-4" aria-label="Close" v-on:click="closeComboModal">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.0693 0.000495159C28.2555 0.08988 36.232 8.20359 35.995 18.4788C35.7676 28.3179 27.783 36.0673 17.8513 35.9996C7.69758 35.9318 -0.412937 27.4498 0.0162846 17.2138C0.415718 7.66998 8.3448 -0.0712836 18.0693 0.000495159ZM18.1045 21.8713C18.2507 22.0975 18.3509 22.3074 18.4999 22.4699C19.6115 23.6821 20.6988 24.9172 21.8605 26.0792C22.8517 27.0692 24.3181 27.0773 25.3552 26.2052C26.3586 25.3601 26.5969 23.9164 25.8576 22.7828C25.5529 22.3156 25.1305 21.9242 24.75 21.5097C23.7033 20.3721 22.6513 19.2413 21.5911 18.0955C22.9464 16.6342 24.2558 15.2365 25.5475 13.8226C26.4642 12.8204 26.5319 11.481 25.7533 10.4165C25.0465 9.45226 23.7128 9.07035 22.6215 9.61749C22.134 9.86262 21.7021 10.2621 21.3176 10.6617C20.1992 11.8291 19.1187 13.0317 18.0111 14.2316C16.7722 12.8976 15.5969 11.6192 14.4081 10.3542C13.6864 9.58634 12.8388 9.14619 11.7501 9.46039C9.70964 10.0482 9.06513 12.2543 10.4923 13.8348C11.7745 15.2569 13.0893 16.6491 14.4135 18.0793C14.2632 18.254 14.1386 18.4097 14.0032 18.556C12.8415 19.8155 11.6784 21.0723 10.5166 22.3318C9.44155 23.4992 9.4754 25.0892 10.5938 26.1293C11.6892 27.1478 13.2301 27.0651 14.3268 25.9072C15.5481 24.6179 16.7546 23.3137 18.1045 21.8686V21.8713Z" fill="#4C4E52"/>
                </svg>
          </button>
          <div class="container mx-auto p-4 w-screen">
            <h1 class="text-2xl mb-4">Daily Combo Cards</h1>
            <div class="flex justify-center w-full ms-1 mt-14">
              <DropZone :cards="cardsWithIndex" @update:cards="updateCards" />
            </div>
            <h3 class="font-normal text-base mt-10" v-if="!user.isComboClaimed">You have {{ user?.comboAttemptsLeft ?? 0 }} attempts left.</h3>
            <h3 class="font-normal text-base mt-10" v-else>You already claimed daily combo reward.</h3>
            <h4 class="font-normal text-base mt-10">Complete the combination by swiping the images.</h4>
            <button class="bg-white rounded-full w-full py-3 mt-10" v-on:click="tryCombo" v-if="!user.isComboClaimed && user.comboAttemptsLeft != 0">
              <h1 class="text-black font-bold text-xl">Try Your Chance!</h1>
            </button>
            <button v-else-if="user.isComboClaimed || user.comboAttemptsLeft == 0" disabled class="bg-gray-400 rounded-full w-full py-3 mt-10">
              <h1 class="text-black font-bold text-xl">You already tried!</h1>
            </button>
          </div>
        </div>
      </dialog>
      <dialog id="status_modal" class="modal text-white">
        <div class="modal-box h-2/5 rounded-t-2xl p-0 bg-black border-white border-opacity-25">
          <button class="icon-button absolute top-4 right-4" aria-label="Close" v-on:click="closeAttemptModal">
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.0693 0.000495159C28.2555 0.08988 36.232 8.20359 35.995 18.4788C35.7676 28.3179 27.783 36.0673 17.8513 35.9996C7.69758 35.9318 -0.412937 27.4498 0.0162846 17.2138C0.415718 7.66998 8.3448 -0.0712836 18.0693 0.000495159ZM18.1045 21.8713C18.2507 22.0975 18.3509 22.3074 18.4999 22.4699C19.6115 23.6821 20.6988 24.9172 21.8605 26.0792C22.8517 27.0692 24.3181 27.0773 25.3552 26.2052C26.3586 25.3601 26.5969 23.9164 25.8576 22.7828C25.5529 22.3156 25.1305 21.9242 24.75 21.5097C23.7033 20.3721 22.6513 19.2413 21.5911 18.0955C22.9464 16.6342 24.2558 15.2365 25.5475 13.8226C26.4642 12.8204 26.5319 11.481 25.7533 10.4165C25.0465 9.45226 23.7128 9.07035 22.6215 9.61749C22.134 9.86262 21.7021 10.2621 21.3176 10.6617C20.1992 11.8291 19.1187 13.0317 18.0111 14.2316C16.7722 12.8976 15.5969 11.6192 14.4081 10.3542C13.6864 9.58634 12.8388 9.14619 11.7501 9.46039C9.70964 10.0482 9.06513 12.2543 10.4923 13.8348C11.7745 15.2569 13.0893 16.6491 14.4135 18.0793C14.2632 18.254 14.1386 18.4097 14.0032 18.556C12.8415 19.8155 11.6784 21.0723 10.5166 22.3318C9.44155 23.4992 9.4754 25.0892 10.5938 26.1293C11.6892 27.1478 13.2301 27.0651 14.3268 25.9072C15.5481 24.6179 16.7546 23.3137 18.1045 21.8686V21.8713Z" fill="#4C4E52"/>
              </svg>
          </button>
          <div class="p-4 w-full flex flex-col h-full justify-center items-center">
            <h1 class="text-2xl mb-4">Status</h1>
            <p>{{ comboStatus ? 'You won!' : 'Please try again!' }}</p>
            <button class="bg-white rounded-full px-24 py-3 mt-10" v-on:click="closeAttemptModal">
              <h1 class="text-black font-bold text-2xl">Ok!</h1>
            </button>
          </div>
        </div>
      </dialog>
      <dialog id="reward_modal" class="bg-modal-bg p-8 rounded-lg border-modal-border">
                <form method="dialog">
                  <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 text-white" @click="closeModal">✕</button>
                  <h3 class="font-semibold text-2xl text-primary-green">Congratulations!</h3>
                  <p class="py-4 text-white">You have earned {{ reward?.value ?? 0 }} $NOW</p>
                  <button class="btn bg-primary-yellow border-none" v-on:click="fortuneClaim(reward?.value)">Claim</button>
                </form>
              </dialog>
          <dialog id="wheel_modal" class="modal modal-bottom sm:modal-middle rounded-t-2xl p-0">
                <div class="modal-box h-4/5 rounded-t-2xl p-0 border-w bg-gradient-to-t from-[#222222] to-black rounded-tl-[31px] rounded-tr-[31px] border-l border-r border-t border-white border-opacity-50">
                  <button class="icon-button absolute top-4 right-4" aria-label="Close" v-on:click="closeWheelModal">
                      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.0693 0.000495159C28.2555 0.08988 36.232 8.20359 35.995 18.4788C35.7676 28.3179 27.783 36.0673 17.8513 35.9996C7.69758 35.9318 -0.412937 27.4498 0.0162846 17.2138C0.415718 7.66998 8.3448 -0.0712836 18.0693 0.000495159ZM18.1045 21.8713C18.2507 22.0975 18.3509 22.3074 18.4999 22.4699C19.6115 23.6821 20.6988 24.9172 21.8605 26.0792C22.8517 27.0692 24.3181 27.0773 25.3552 26.2052C26.3586 25.3601 26.5969 23.9164 25.8576 22.7828C25.5529 22.3156 25.1305 21.9242 24.75 21.5097C23.7033 20.3721 22.6513 19.2413 21.5911 18.0955C22.9464 16.6342 24.2558 15.2365 25.5475 13.8226C26.4642 12.8204 26.5319 11.481 25.7533 10.4165C25.0465 9.45226 23.7128 9.07035 22.6215 9.61749C22.134 9.86262 21.7021 10.2621 21.3176 10.6617C20.1992 11.8291 19.1187 13.0317 18.0111 14.2316C16.7722 12.8976 15.5969 11.6192 14.4081 10.3542C13.6864 9.58634 12.8388 9.14619 11.7501 9.46039C9.70964 10.0482 9.06513 12.2543 10.4923 13.8348C11.7745 15.2569 13.0893 16.6491 14.4135 18.0793C14.2632 18.254 14.1386 18.4097 14.0032 18.556C12.8415 19.8155 11.6784 21.0723 10.5166 22.3318C9.44155 23.4992 9.4754 25.0892 10.5938 26.1293C11.6892 27.1478 13.2301 27.0651 14.3268 25.9072C15.5481 24.6179 16.7546 23.3137 18.1045 21.8686V21.8713Z" fill="#4C4E52"/>
                      </svg>
                  </button>
                  <div class="flex flex-col content-center justify-center items-center mt-10 relative">
                    <h3 class="text-white font-bold text-3xl">Fortune Wheel</h3>
                    <FortuneWheel
                      ref="wheelEl"
                      id="wheel"
                      type="image"
                      :useWeight="true"
                      :verify="canvasVerify"
                      :prizeId="prizeId"
                      :angleBase="-2"
                      :prizes="prizesImage"
                      @rotateStart="onImageRotateStart"
                      @rotateEnd="onRotateEnd"
                      class="mt-10"
                      >
                      <template #wheel>
                        <div class="w-full flex justify-center"> 
                          <img src="../assets/wheel.svg" style="width: 95%; transform: rotateZ(380deg)" />
                        </div>
                      </template>
                    </FortuneWheel>
                    <img src="../assets/wheel_btn.svg" style="width: 100px; margin-top: 70px;" class="absolute"/>
                  </div>
                  
                  <button class="bg-white rounded-full px-36 py-3 mt-10" v-on:click="turn">
                    <h1 class="text-black font-bold text-2xl">Play!</h1>
                  </button>

                  <h1 class="text-white text-base font-bold mt-5">00h 00m</h1>
                </div>
            </dialog>

          <dialog id="leaderboard_modal" class="modal modal-bottom sm:modal-middle rounded-t-2xl p-0 text-white">
            <div class="modal-box h-full rounded-t-2xl p-0 border-w bg-gradient-to-t from-[#222222] to-black rounded-tl-[31px] rounded-tr-[31px] border-l border-r border-t border-white border-opacity-60">
              <div class="mt-15">
                <div class="swiper-container mt-5 px-2">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="rank in store.state.ranks" :key="rank.name">
                      <button class="icon-button absolute top-4 right-4" aria-label="Close" @click="closeLeaderboard">
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M18.0693 0.000495159C28.2555 0.08988 36.232 8.20359 35.995 18.4788C35.7676 28.3179 27.783 36.0673 17.8513 35.9996C7.69758 35.9318 -0.412937 27.4498 0.0162846 17.2138C0.415718 7.66998 8.3448 -0.0712836 18.0693 0.000495159ZM18.1045 21.8713C18.2507 22.0975 18.3509 22.3074 18.4999 22.4699C19.6115 23.6821 20.6988 24.9172 21.8605 26.0792C22.8517 27.0692 24.3181 27.0773 25.3552 26.2052C26.3586 25.3601 26.5969 23.9164 25.8576 22.7828C25.5529 22.3156 25.1305 21.9242 24.75 21.5097C23.7033 20.3721 22.6513 19.2413 21.5911 18.0955C22.9464 16.6342 24.2558 15.2365 25.5475 13.8226C26.4642 12.8204 26.5319 11.481 25.7533 10.4165C25.0465 9.45226 23.7128 9.07035 22.6215 9.61749C22.134 9.86262 21.7021 10.2621 21.3176 10.6617C20.1992 11.8291 19.1187 13.0317 18.0111 14.2316C16.7722 12.8976 15.5969 11.6192 14.4081 10.3542C13.6864 9.58634 12.8388 9.14619 11.7501 9.46039C9.70964 10.0482 9.06513 12.2543 10.4923 13.8348C11.7745 15.2569 13.0893 16.6491 14.4135 18.0793C14.2632 18.254 14.1386 18.4097 14.0032 18.556C12.8415 19.8155 11.6784 21.0723 10.5166 22.3318C9.44155 23.4992 9.4754 25.0892 10.5938 26.1293C11.6892 27.1478 13.2301 27.0651 14.3268 25.9072C15.5481 24.6179 16.7546 23.3137 18.1045 21.8686V21.8713Z" fill="#4C4E52"/>
                        </svg>
                      </button>
                      <div class="flex flex-col justify-center items-center mt-3 w-full">
                        <img :src="rank.icon" :alt="rank.name" style="height: 120px; width: 120px;">
                        <h4 class="text-2xl font-bold text-white mt-2">{{ rank.name }}</h4>
                      </div>
                      <h4 class="text-base font-medium text-slate-300">From {{ rank.minRange }}</h4>
                      <div class="relative flex justify-center items-center mt-6">
                        <div class="w-[350px] h-[0px] border border-[#6b6b6b]/40"></div>
                        <div class="absolute">
                          <svg width="17" height="17" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 7.5L7.5 0L15 7.5L7.5 15L0 7.5Z" fill="#FCA137"/>
                          </svg>
                        </div>
                      </div>

                      <div class="flex flex-col justify-between">
                        <div class="friends-wrapper flex flex-col w-full py-4 min-h-28">
                          <div class="mt-5 mx-5 border bg-[#131313] rounded-2xl p-3 flex items-center justify-between relative overflow-hidden" v-for="(user, index) in getLeaderBoard(rank)" :key="user.name">
                            <div class="flex items-center">
                              <div class="relative w-12 h-12 flex justify-center items-center flex-col">
                                <div class="absolute w-12 h-12 rounded-full border-secondary-yellow border-4"></div>
                                <h4 class="text-secondary-yellow text-xl font-bold">{{ index + 1 }}</h4>
                              </div>
                              <h4 class="text-white font-bold text-lg ms-3">{{ user.name }}</h4>
                            </div>
                            <div class="flex items-center gap-2">
                              <h4 class="text-white font-semibold text-xl">{{ user.balance.toFixed(0) }}</h4>
                              <img :src="store.state.appIcon" alt="appicon" class="h-6 w-6 rounded-full"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </dialog>
        <DailyRewardModal ref="dailyRewardModal" @close="handleModalClose" @show="handleModalShow" />
    </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { useVibrate } from '@vueuse/core';
import store from '@/store';
import userService from '@/service/userService';
import FortuneWheel from 'vue-fortune-wheel';
import 'vue-fortune-wheel/style.css';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import DropZone from '@/components/DropZone.vue'
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import DailyRewardModal from '@/components/DailyRewardModal.vue';
import router from '@/router';

const $toast = useToast();

// eslint-disable-next-line
const { vibrate, stop } = useVibrate();

const card = ref(null);
const cards = ref([]);
const dailyRewardModal = ref(null);

const cardsWithIndex = computed(() => {
    return store.state.comboCards.cards.map((card, index) => {
        card.index = index + 1;

        return card;
    });
});

const clicks = ref([]);
const comboStatus = ref();
const prizeId = ref(0)
const canvasVerify = ref(false)
const swiper = ref(null);
const isFirstSlide = ref(true);
const isLastSlide = ref(false);
const wheelEl = ref()
const prizesImage = [
    {
      id: 1,
      value: 80, 
      weight: 5,
    },
    {
      id: 2,
      value: 140,
      weight: 3
    },
    {
      id: 3,
      value: 200,
      weight: 3
    },
    {
      id: 4, 
      value: 300,
      weight: 2
    },
    {
      id: 5,
      value: 240,
      weight: 3
    },
    {
      id: 6,
      value: 40,
      weight: 4
    }
];

const reward = ref();

const user = computed(() => {
  return store.state.user;
});

const routeBoosts = () => {
  router.push({ name: 'boosts' });
}

const showModal = () => {
  if(store.state.nextDailyClaim >= new Date()) {
    return;
  }

  document.getElementById('my_modal_5').showModal();
};

const onRotateEnd = (prize) => {
  reward.value = prize;

  document.getElementById('reward_modal').showModal();
};

const onImageRotateStart = () => {
  console.log('Rotate started');
};

const turn = () => {
  wheelEl.value.startRotate();
};

const updateCards = (newCards) => {
  cards.value = newCards;

  console.log(cards);
}

const getLeaderBoard = (rank) => {  
  const users = store.state.leaderboard.find(leaderboard => {
    return rank.id == leaderboard.rankId;
  }).topUsers;

  return users;
};

const userRank = computed(() => {
  return store.state.ranks.find(rank => {
    return user.value.allTimeBalance >= rank.minRange && user.value.allTimeBalance <= rank.maxRange;
  });
});

const showLeaderboard = () => {
  document.getElementById('leaderboard_modal').showModal();
}

const closeLeaderboard = () => {
  document.getElementById('leaderboard_modal').close();
}

const handleModalClose = () => {
  if (store.state.nextDailyClaim >= new Date()) {
    return;
  }

  document.getElementById('my_modal_5').showModal();
};

const handleModalShow = () => {
  document.getElementById('my_modal_5').close();
};

const shareRef = () => {
  const shareLink = `https://t.me/Patheum_bot/app?startapp=${user.value.telegramId}`;
  const shareText = "Come join to Patheum to earn rewards!";
  window.open(`https://telegram.me/share/url?url=${encodeURIComponent(shareLink)}&text=${encodeURIComponent(shareText)}`, '_blank');
};

const tapClaim = async (event) => {
  if((user.value.energy - 1) <= 1) return;

  const userId = store.state.user.telegramId;

  userService.tapClaim(userId);

  const balance = store.state.user.balance + store.state.tapClaimReward;
  user.value.tapCount += 1;
  user.value.energy -= 1;

  store.commit('setBalance', balance);
  store.commit('setUser', user.value);

  vibrate();

  const rect = card.value.getBoundingClientRect();
  const x = event.clientX - rect.left - rect.width / 2;
  const y = event.clientY - rect.top - rect.height / 2;

  card.value.style.transform = `perspective(1000px) rotateX(${-y / 10}deg) rotateY(${x / 10}deg)`;

  setTimeout(() => {
    card.value.style.transform = '';
  }, 150);

  clicks.value.push({ id: Date.now(), x: event.pageX, y: event.pageY });
}

const handleAnimationEnd = (id) => {
    clicks.value = clicks.value.filter(click => click.id !== id);
};

const tryCombo = async () => {
  try {
    const comboIds = cards.value.map(card => card.id).join('');

    const res = await userService.claimCombo(store.state.user.telegramId, comboIds);

    comboStatus.value = res.success;

    if(res.user) {
      store.commit('setUser', res.user);
      if(res.nextComboClaim) {
        store.commit('setNextComboClaim', new Date(res.nextComboClaim));
      }

      if(res.user.comboAttemptsLeft == 0) {
        closeComboModal();
      }
    }

    showAttemptModal();
  } catch(e) {
    comboStatus.value = false;
    return e;
  }
}

const openWheelModal = () => {
  if (store.state.nextWheelClaim >= new Date()) {
    return;
  }

  document.getElementById('wheel_modal').showModal();
};

const closeWheelModal = () => {
  document.getElementById('wheel_modal').close();
};

const showAttemptModal = () => {
  const modal = document.getElementById('status_modal');

  modal.showModal();
}

const closeAttemptModal = () => {
  const modal = document.getElementById('status_modal');

  modal.close();

  if(comboStatus.value) {
    closeComboModal();
  }
}

const showComboModal = () => {
  if (store.state.nextComboClaim >= new Date()) {
    return;
  }

  document.getElementById('combo_modal').showModal();
}

const closeComboModal = () => {
  document.getElementById('combo_modal').close();
}

const fortuneClaim = async (value) => {
    closeWheelModal();

    if(new Date() <= store.state.nextWheelClaim) {
      return;
    }

    try {
      await userService.claimWheelReward(user.value.telegramId, value);

      const data = await userService.getUser(user.value.telegramId); 

      $toast.success('Claimed successfully', { position: 'top', duration: 1500 });

      const nextWheelClaimDate = new Date(data.nextWheelClaim);
      console.log(nextWheelClaimDate);
      
      store.commit('setUser', data.user);
      store.commit('setNextWheelClaim', nextWheelClaimDate);

      reward.value = null;

      setInterval(() => {
          const currentTime = new Date().getTime();
          const timeLeft = nextWheelClaimDate - currentTime;
          
          if (timeLeft < 0) {
            store.commit('setWheelCountdown', '00:00:00');

            return;
          }

          const hours = Math.floor(timeLeft / (1000 * 60 * 60)).toString().padStart(2, '0');
          const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
          const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000).toString().padStart(2, '0');

          store.commit('setWheelCountdown', `${hours}:${minutes}:${seconds}`);
      }, 1000);
    } catch(e) {
      console.log(e);
      $toast.error('Something went wrong, please try again later');
    }
}

onMounted(() => {
  window.Telegram.WebApp.expand();

  const index = store.state.ranks.findIndex((rank) => rank.id == userRank.value.id);

  Swiper.use([Navigation, Pagination]);
  swiper.value = new Swiper('.swiper-container', {
    loop: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    initialSlide: index,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    on: {
      slideChange: (swiper) => {
        isFirstSlide.value = swiper.isBeginning;
        isLastSlide.value = swiper.isEnd;
      }
    }
  });

});
</script>


<style>
.progress-border {
    background: conic-gradient(#4caf50 0% 25%, transparent 25% 100%);
    border-radius: 50%;
    mask: radial-gradient(farthest-side, transparent calc(100% - 4px), white calc(100% - 4px));
    -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - 4px), white calc(100% - 4px));
}

.progress-border::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid transparent;
    animation: progress 2s linear infinite;
}

.tapped {
  animation: tapAnimation 0.5s ease;
}

@keyframes tapAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 767.98px) {
  .position-absolute.rounded-circle.border {
    width: 12rem;
    height: 12rem;
  }
  img.position-relative {
    width: 10rem;
    height: 10rem;
  }
}

@media (max-width: 575.98px) {
  .position-absolute.rounded-circle.border {
    width: 8rem;
    height: 8rem;
  }
  img.position-relative {
    width: 7rem;
    height: 7rem;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

@keyframes float {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}

.shadow-gradient {
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.1), 0 0 50px rgba(0, 0, 0, 0.1);
}
</style>
