<template>
    <div class="bg-[#131313] p-2 rounded-lg flex flex-col items-center justify-between relative" :class="!isPurchasable ? 'opacity-85' : ''" v-on:click="purchase">
        <div class="flex gap-2 w-full justify-center items-center">
            <img :src="equipment.icon" alt="equipment" style="height: 50px; width: 50px;">
            <div class="flex flex-col items-start justify-start">
                <h1 class="text-white text-base font-normal text-start">{{ equipment.title }}</h1>
                <h3>Earn/hr</h3>
                <div class="flex gap-1 items-center justify-center">
                    <img src="../assets/coin.svg" alt="coin" style="height: 20px; width: 20px;">
                    <h4 class="text-[#fca137] font-bold text-base">+{{ formattedAmount }}</h4>
                </div>
            </div>
        </div>

        <div class="w-[146px] h-[0px] border border-[#6b6b6b]/40 mt-2"></div>
        
        <div class="flex justify-between w-full px-3 mt-2" v-if="!isPurchased">
            <h3>Level {{ equipment?.level ?? 0 }}</h3>
            <div class="flex gap-1 items-center justify-center">
                <img src="../assets/coin.svg" alt="coin" style="height: 20px; width: 20px;">
                <h3 class="text-white text-base font-bold">{{ formattedPrice }}</h3>
            </div>
        </div>

        <div class="flex justify-between items-center w-full px-1 mt-2" v-else>
            <h3>Level {{ equipment?.level ?? 0 }}</h3>
            <h3 class="text-[#fca137] font-bold text-sm">Purchased</h3>
        </div>

        <!-- Add this new overlay div -->
        <div v-if="!isPurchasable" class="absolute inset-0 bg-black bg-opacity-50 rounded-lg flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
            </svg>
        </div>
    </div>
</template>

<script setup>
import { defineProps, computed, defineEmits } from 'vue';
import store from '@/store/index';

const emit = defineEmits(['purchase'])

const props = defineProps({
    equipment: {
        type: Object,
        required: true,
    }
});

function formatNumber(num) {
    if (num >= 1e6) return (num / 1e6).toFixed(1) + 'M';
    if (num >= 1e3) return (num / 1e3).toFixed(0) + 'k';
    return num.toString();
}

const formattedAmount = computed(() => formatNumber(props.equipment.amount));
const formattedPrice = computed(() => formatNumber(props.equipment.price));

const isDisabled = computed(() => {
    return isPurchased.value || store.state.user.balance < props.equipment.price;
});

const isPurchased = computed(() => {
    return store.state.user.purchasedEquipments.includes(props.equipment._id);
});

const isPurchasable = computed(() => {
    return store.state.user.balance >= props.equipment.price || isPurchased.value;
});

const purchase = async () => {
    if(isDisabled.value == true) return;

    emit('purchase', props.equipment);
}
</script>
