<template>
    <dialog id="my_modal_5" class="modal modal-bottom sm:modal-middle rounded-t-2xl p-0 text-white">
        <div class="modal-box h-3/5 rounded-t-2xl p-0 border-w bg-gradient-to-t from-[#222222] to-black rounded-tl-[31px] rounded-tr-[31px] border-l border-r border-t border-white">
            <button class="icon-button absolute top-4 right-4" aria-label="Close" v-on:click="closeModal">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.0693 0.000495159C28.2555 0.08988 36.232 8.20359 35.995 18.4788C35.7676 28.3179 27.783 36.0673 17.8513 35.9996C7.69758 35.9318 -0.412937 27.4498 0.0162846 17.2138C0.415718 7.66998 8.3448 -0.0712836 18.0693 0.000495159ZM18.1045 21.8713C18.2507 22.0975 18.3509 22.3074 18.4999 22.4699C19.6115 23.6821 20.6988 24.9172 21.8605 26.0792C22.8517 27.0692 24.3181 27.0773 25.3552 26.2052C26.3586 25.3601 26.5969 23.9164 25.8576 22.7828C25.5529 22.3156 25.1305 21.9242 24.75 21.5097C23.7033 20.3721 22.6513 19.2413 21.5911 18.0955C22.9464 16.6342 24.2558 15.2365 25.5475 13.8226C26.4642 12.8204 26.5319 11.481 25.7533 10.4165C25.0465 9.45226 23.7128 9.07035 22.6215 9.61749C22.134 9.86262 21.7021 10.2621 21.3176 10.6617C20.1992 11.8291 19.1187 13.0317 18.0111 14.2316C16.7722 12.8976 15.5969 11.6192 14.4081 10.3542C13.6864 9.58634 12.8388 9.14619 11.7501 9.46039C9.70964 10.0482 9.06513 12.2543 10.4923 13.8348C11.7745 15.2569 13.0893 16.6491 14.4135 18.0793C14.2632 18.254 14.1386 18.4097 14.0032 18.556C12.8415 19.8155 11.6784 21.0723 10.5166 22.3318C9.44155 23.4992 9.4754 25.0892 10.5938 26.1293C11.6892 27.1478 13.2301 27.0651 14.3268 25.9072C15.5481 24.6179 16.7546 23.3137 18.1045 21.8686V21.8713Z" fill="#4C4E52"/>
                </svg>
            </button>
            <div class="flex flex-col content-center justify-start items-center mt-10">
              <h3 class="font-semibold text-4xl text-modal-text">Daily Reward</h3>
              <div class="rounded-md ms-2 px-2 py-1">
                <h3 class="font-semibold text-base text-primary-gray">Visit daily to claim your rewards</h3>
              </div>
            </div>

            <div class="flex justify-center items-center mt-7 overflow-hidden">
              <div class="h-44 bg-slate-700 relative mx-2 text-white rounded-2xl text-center flex flex-col daily-cards">
                <div class="rounded-full h-16 w-16  border-4 justify-center flex items-center content-center mt-5 mx-8">
                  <div class="absolute bg-[#3A6CCF] w-12 h-12 rounded-full"></div>
                  <h3 class="relative text-black font-bold text-3xl">{{ user.dailyClaim + 1 }}</h3>
                </div>
                <div class="relative flex flex-col justify-start items-center">
                    <h1 class="text-white font-semibold text-base mx-8 mt-2">{{ dailyReward(user.dailyClaim + 1)}}</h1>
                    <h2 class="text-gray-400 font-semibold text-base mx-8">$PTH </h2>
                </div>
                <div class="rounded-b-2xl bg-white absolute bottom-0 flex justify-center w-full">
                  <h1 class="text-black text-lg font-semibold">DAY {{ user.dailyClaim + 1 }}</h1>
                </div>
              </div>
            </div>
            
            <button class="bg-white rounded-full px-36 py-3 mt-10" v-on:click="claimDaily">
              <h1 class="text-black font-bold text-2xl">Claim</h1>
            </button>

            <h1 class="text-white text-base font-bold mt-5">00h 00m</h1>
        </div>
      </dialog>
</template>

<script setup>
import store from '@/store';
import { computed, defineEmits } from 'vue';
import userService from '@/service/userService';

const emit = defineEmits(['close', 'show']);

const rewards = computed(() => {
  return store.state.dailyRewards;
});

const dailyReward = (day) => {
  return rewards.value.find(reward => reward.day == day).reward;
};

const user = computed(() => {
    return store.state.user;
});

const closeModal = () => {
  document.getElementById('my_modal_5').close();
  emit('close');
}

const claimDaily = async () => {
  if(new Date() <= store.state.nextDailyClaim) {
      return;
  }

  console.log('Claiming daily reward'); 

  try {
    await userService.claimDaily(store.state.user.telegramId);
    const res = await userService.getUser(store.state.user.telegramId);

    document.getElementById('my_modal_5').close();
    
    if(!res.error) {
      store.commit('setUser', res.user);
      store.commit('setNextDailyClaim', new Date(res.nextDailyClaim));
      emit('show');
      return;
    }
  } catch(e) {
    console.log(e);
  }
}

</script>