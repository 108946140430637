<template>
    <div class="mt-5 bg-[#131313] rounded-2xl p-2 flex items-center justify-between relative overflow-hidden" v-on:click="goLink">
        <div class="flex gap-3 ms-1 items-center justify-start">
            <img :src="mission.platform"  alt="icon" class="h-12 w-12 overflow-visible rounded-full" style="object-fit: contain;">
            <div class="flex flex-col items-start">
                <h4 class="text-[#FCA137] font-semibold text-lg text-start">{{ mission.name }}</h4>
                <div class="flex gap-2 items-center">
                    <img :src="this.$store.state.appIcon" alt="appicon" class="h-5 w-5 rounded-full"/>
                    <h3 class="text-white font-bold text-base">+{{ mission.reward }}</h3>
                </div>
            </div>
        </div>

        <div class="flex justify-end" v-if="isLoading">
            <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </div>
       
        <div v-if="!isMissionDone">
            <button type="button" v-on:click="goToLink" v-if="!isLinkDone && !isLoading" class="me-3">
                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99705 9L0 1.00164L1.00147 0L10 9L1.00147 18L0 16.9984L7.99705 9Z" fill="white"/>
                </svg>
            </button>

            <button type="button" v-on:click="claimMission" v-else-if="isLinkDone && !isLoading" class="text-[#FCA137] font-semibold rounded-full text-base px-5 py-2 mt-2 mb-2 text-center" :class="{ 'bg-blue-button': isLinkOpened }">
                <h1>Claim</h1>
            </button>
        </div>

        <div v-if="isMissionDone" class="flex justify-center">
            <button type="button" v-on:click="goLink" class="text-white bg-gray-400 font-semibold rounded-full text-base px-5 py-2 mt-2 mb-2 text-center" :class="{ 'bg-blue-button': isLinkOpened }">
                <h1>Claimed!</h1>
            </button>
        </div>

        <dialog id="youtube_modal" class="modal modal-bottom sm:modal-middle rounded-t-2xl p-0 text-white">
            <div class="modal-box h-3/5 rounded-t-2xl p-0 bg-slate-900">
                <button class="icon-button absolute top-4 right-4" aria-label="Close" v-on:click="closeModal">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.0693 0.000495159C28.2555 0.08988 36.232 8.20359 35.995 18.4788C35.7676 28.3179 27.783 36.0673 17.8513 35.9996C7.69758 35.9318 -0.412937 27.4498 0.0162846 17.2138C0.415718 7.66998 8.3448 -0.0712836 18.0693 0.000495159ZM18.1045 21.8713C18.2507 22.0975 18.3509 22.3074 18.4999 22.4699C19.6115 23.6821 20.6988 24.9172 21.8605 26.0792C22.8517 27.0692 24.3181 27.0773 25.3552 26.2052C26.3586 25.3601 26.5969 23.9164 25.8576 22.7828C25.5529 22.3156 25.1305 21.9242 24.75 21.5097C23.7033 20.3721 22.6513 19.2413 21.5911 18.0955C22.9464 16.6342 24.2558 15.2365 25.5475 13.8226C26.4642 12.8204 26.5319 11.481 25.7533 10.4165C25.0465 9.45226 23.7128 9.07035 22.6215 9.61749C22.134 9.86262 21.7021 10.2621 21.3176 10.6617C20.1992 11.8291 19.1187 13.0317 18.0111 14.2316C16.7722 12.8976 15.5969 11.6192 14.4081 10.3542C13.6864 9.58634 12.8388 9.14619 11.7501 9.46039C9.70964 10.0482 9.06513 12.2543 10.4923 13.8348C11.7745 15.2569 13.0893 16.6491 14.4135 18.0793C14.2632 18.254 14.1386 18.4097 14.0032 18.556C12.8415 19.8155 11.6784 21.0723 10.5166 22.3318C9.44155 23.4992 9.4754 25.0892 10.5938 26.1293C11.6892 27.1478 13.2301 27.0651 14.3268 25.9072C15.5481 24.6179 16.7546 23.3137 18.1045 21.8686V21.8713Z" fill="#4C4E52"/>
                    </svg>
                </button>
                <div class="rounded-lg p-4 max-w-md mx-4">
                    <h2 class="text-xl font-semibold mt-10">Enter YouTube Code</h2>
                    <input 
                        v-model="code" 
                        type="number" 
                        placeholder="Enter code here..." 
                        class="w-full p-2 rounded-md mb-4 mt-5"
                    />
                    <button v-on:click="submitCode" class="bg-white w-full text-black py-2 rounded-lg">Submit</button>
                </div>
            </div>
      </dialog>
    </div>
</template>

<script>
import UserService from '@/service/userService';

export default {
    name: 'MissionCard',
    props: {
        mission: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            code: '',
        }
    },
    methods: {
        goLink() {
            if(this.isMissionDone) {
                window.open(this.mission.missionLink, '_blank');
            }
        },

        async goToLink() {
            if (this.isMissionDone) return;

            // Set loading state
            this.$store.commit('addVerifyingMission', this.mission._id);
            
            // Open the link in a new tab
            window.open(this.mission.missionLink, '_blank');

            // Wait for 10 seconds
            await new Promise(resolve => setTimeout(resolve, 10000));
            
            // Set loading state to false and mark the link as done
            this.$store.commit('removeVerifyingMission', this.mission._id);

            this.$store.commit('addLinkDone', this.mission._id);
        },

        async claimMission() {
            if (!this.isLinkDone) return;

            if (this.mission.missionLink.includes('tube')) {
                this.openModal();
                return;
            }

            await UserService.claimMission(this.$store.state.user.telegramId, this.mission._id);
            const res = await UserService.getUser(this.$store.state.user.telegramId);

            if(res.user) {            
                this.$store.commit('removeLinkDone', this.mission._id);
                this.$store.commit('setUser', res.user);
            }
        },

        openModal() {
            document.getElementById('youtube_modal').showModal();
        },
      
        closeModal() {
            document.getElementById('youtube_modal').close();
            this.code = '';
        },

        async submitCode() {
            const missionRes = await UserService.claimMission(this.$store.state.user.telegramId, this.mission._id, this.code);
            const res = await UserService.getUser(this.$store.state.user.telegramId);

            console.log(missionRes);

            if(!missionRes.error) {
                if(res.user) {            
                    this.$store.commit('removeLinkDone', this.mission._id);
                    this.$store.commit('setUser', res.user);
    
                    this.$toast.success('Claimed successfuly', { position: 'top', duration: 1500 });
                }
            } else {
                this.$toast.error(missionRes.error, { position: 'top', duration: 1500 });
            }

            this.closeModal();
        }
    },

    computed: {
        user() {
            return this.$store.state.user;
        },

        isMissionDone() {
            return this.user.missionsDone.includes(this.mission._id);
        },
        
        isLinkDone() {
            return this.$store.state.linksDone.includes(this.mission._id);
        },

        isLoading() {
            return this.$store.getters.verifyingMission.includes(this.mission._id);
        },
    }
}
</script>