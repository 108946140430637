<template>
    <div class="flex flex-col stats bg-transparent h-screen w-screen">
      <div class="content w-screen flex-col justify-center items-center">
        <div class="w-full flex flex-col justify-center mt-5 items-center">
          <img src="../assets/missions_hero.svg" alt="hero">
          <h3 class="text-5xl text-white font-bold">Earn</h3>
        </div>

        <div class="relative flex justify-center items-center mt-5">
          <div class="w-[350px] h-[0px] border border-[#6b6b6b]/40"></div>
          <div class="absolute">
            <svg width="17" height="17" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 7.5L7.5 0L15 7.5L7.5 15L0 7.5Z" fill="#FCA137"/>
            </svg>
          </div>
        </div>

        <div class="p-4 w-screen">
          <MissionCard v-for="mission in missions" :key="mission.id" :mission="mission" />

          <div class="mt-5 border border-opacity-20 border-white bg-slate-800 rounded-2xl p-3 flex items-center justify-between relative overflow-hidden" v-if="refClaim">
              <div class="flex gap-3 ms-1 items-center justify-start">
                  <img src="../assets/share.svg" alt="icon" class="h-8 w-8 overflow-visible rounded-full text-white" style="object-fit: contain;">
                  <div class="flex flex-col items-start">
                      <h4 class="text-white font-semibold text-lg">Invite {{ refClaim.requiredRefCount }} friends!</h4>
                      <div class="flex gap-1 items-center justify-center">
                          <h3 class="text-white font-bold">+{{ refClaim.reward }}</h3>
                          <img :src="store.state.appIcon" alt="appicon" class="h-4 w-4 rounded-full"/>
                      </div>
                  </div>
              </div>
            
              <button type="button" v-on:click="claimRef" :disabled="user.friends.length < refClaim.requiredRefCount" class="text-white font-semibold bg-transparent p-1 text-center" :class="user.friends.length > refClaim.requiredRefCount ? 'bg-[#3F71D4]' : 'bg-gray-400'">
                <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.999998 19L9 10L1 0.999999" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import store from '@/store/index';
import MissionCard from '@/components/MissionCard.vue';
import UserService from '@/service/userService';

const missions = computed(() => {
  return store.state.missions;
});

const user = computed(() => {
  return store.state.user;
})

const refClaim = computed(() => {
  return store.state.refClaims.find(claim => user.value.refClaimLevel ? claim.level == 0 : claim.level == user.value.refClaimLevel + 1);
});

const claimRef = async () => {
  if(refClaim.value.requiredRefCount > user.value.friends.length) return;

  const res = await UserService.claimRef(user.value.telegramId, refClaim.value.level);

  if(res.user) {
    store.commit('setUser', res.user);
  }
}

</script>

<style>
* {
  touch-action: manipulation;
}
</style>